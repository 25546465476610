import { registerHandler } from '@mocks/websocketProxy';

import seriesScoreboard from './seriesScoreboard';
import seriesList from './seriesList';
import predictionsTracker from './predictionsTracker';
import liveLog from './liveLog';
import simpleState from './simpleState';
import seriesTable from './seriesTable';
import seriesTimeline from './seriesTimeline';
import seriesScoreboardV2 from './seriesScoreboardv2';
import feedViewer from './feedViewer';
import seriesComparison from './seriesComparison';
import teamScoreboard from './teamScoreboard';
import teamColors from './teamColors';
import mapSchematic from './mapSchematic';
import netWorth from './netWorth';

const getWidgetGenerator = (event) => {
    switch (event) {
        case 'series_scoreboard_v2':
            return seriesScoreboardV2;
        case 'series_list':
            return seriesList;
        case 'series_list_v2':
            return seriesList;
        case 'series_scoreboard':
            return seriesScoreboard;
        case 'probability_tracker':
            return predictionsTracker;
        case 'live_log':
            return liveLog;
        case 'series_table':
            return seriesTable;
        case 'simple_state':
            return simpleState;
        case 'live-data-feed':
            return feedViewer;
        case 'series_timeline':
            return seriesTimeline;
        case 'series_comparison':
            return seriesComparison;
        case 'team_scoreboard':
            return teamScoreboard;
        case 'team_colors':
            return teamColors;
        case 'series_map_schematic':
            return mapSchematic;
        case 'series_net_worth':
            return netWorth;
        default:
            return simpleState;
    }
};

const generatePayload = (payload, service) => {
    if (service === 'live-data-feed') {
        return JSON.stringify(payload);
    }
    return JSON.stringify({ data: [{ data: JSON.stringify(payload) }], delay: 0 });
};

function handleWidgetData(server, { search }) {
    const { authKey, events } = search;
    const hasAuthKeyAndInvalid = authKey && authKey !== 'test';

    const service = events.split(',')[0];

    if (hasAuthKeyAndInvalid) {
        return;
    }

    server.on('connection', (socket) => {
        if (socket.readyState === 1) {
            const gen = getWidgetGenerator(service)();

            const initialValue = gen.next();
            socket.send(generatePayload(initialValue.value, service));

            const interval = setInterval(() => {
                const nextValue = gen.next();
                if (nextValue.done) {
                    clearInterval(interval);
                    return;
                }

                socket.send(generatePayload(nextValue.value, service));
            }, 10);
        }
    });
}

registerHandler('/widgets-v2/live/:id', handleWidgetData);
registerHandler('/api/widgets/live/:id', handleWidgetData);

const handleFeedViewer = (server) => {
    const service = 'live-data-feed';
    server.on('connection', (socket) => {
        if (socket.readyState === 1) {
            const gen = getWidgetGenerator(service)();

            const initialValue = gen.next();
            socket.send(generatePayload(initialValue.value, service));

            const interval = setInterval(() => {
                const nextValue = gen.next();
                if (nextValue.done) {
                    clearInterval(interval);
                    return;
                }

                socket.send(generatePayload(nextValue.value, service));
            }, 10);
        }
    });
};

registerHandler('/live-data-feed/series/:id', handleFeedViewer);
registerHandler('/live-data-feed/series', handleFeedViewer);
