import { CSSProperties } from 'react';
import styled from '@emotion/styled';

import {
    space,
    SpaceProps,
    color,
    ColorProps,
    layout,
    LayoutProps,
    flexbox,
    FlexboxProps,
    typography,
    TypographyProps,
    background,
    BackgroundProps,
    position,
    PositionProps,
    borders,
    BordersProps,
    system,
    variant,
    compose,
} from '@components/styled/system';
import styledScrollbar, { CustomScrollbarProps } from "@utilities/customScrollbar";

type BoxStyleProps = SpaceProps &
    ColorProps &
    LayoutProps &
    TypographyProps &
    FlexboxProps &
    PositionProps &
    BordersProps &
    BackgroundProps &
    PositionProps &
    BordersProps &
    SpaceProps;

export interface IContainer extends BoxStyleProps {
    userSelect?: string;
    push?: OneOrMany<boolean>;
    customScrollbar?: CustomScrollbarProps;
    transform?: OneOrMany<string>;
    boxShadow?: OneOrMany<string>;
    gap?: OneOrMany<CSSProperties['gap']>;
    rowGap?: OneOrMany<CSSProperties['rowGap']>;
    columnGap?: OneOrMany<CSSProperties['columnGap']>;
}

const styledSystem = compose(
    space,
    color,
    layout,
    flexbox,
    typography,
    background,
    position,
    borders,
);

const pushStyle = variant({
    prop: 'push',
    variants: {
        true: {
            marginLeft: 'auto',
        },
        false: {
            marginLeft: '0',
        },
    },
});

const gap = system({
    gap: {
        property: 'gap',
        scale: 'space',
    },
    rowGap: {
        property: 'rowGap',
        scale: 'space',
    },
    columnGap: {
        property: 'columnGap',
        scale: 'space',
    },
});

const Flex = styled.div<IContainer>`
    display: flex;
    box-sizing: border-box;
    ${styledSystem}
    ${pushStyle}
    ${({ customScrollbar }) => (customScrollbar ? styledScrollbar(customScrollbar) : '')}
    ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}
    ${({ userSelect }) => (userSelect ? `user-select: ${userSelect};` : '')}
    ${system({
        transform: true,
        boxShadow: true,
    })}
    ${gap}
`;

export default Flex;
