import { rest } from 'msw';
import { PermissionObjectSet } from 'typings/permissions';
import contentPermissionsMock from '@features/dashboard/pages/central-data/pages/components/ContentPermissions/components/ResultsTree/fixtures/data.json';
import filteredContentPermissionsMock from '@features/dashboard/pages/central-data/pages/components/ContentPermissions/components/ResultsTree/fixtures/filtered.json';

interface PermissionsResponse {
    actionPermissions: PermissionObjectSet;
}

const { organisations } = contentPermissionsMock;
const users = organisations.reduce(
    (usersAcc, organisation) => [...(organisation.users || []), ...usersAcc],
    [],
);

const permissionsResponse: PermissionsResponse = {
    actionPermissions: [
        {
            action: 'read',
            permissions: [
                'rapid_dashboard_view',
                'rapid_virtuals_dashboard_view',
                'rapid_console_view',
                'rapid_multiview_view',
                'widget_overlay_view',
                'widget_embed_view',
                'data_feed_viewer_view',
                'ops_tooling_admin',
                'central_data_series_all',
                'central_data_titles_all',
                'central_data_tournaments_all',
                'central_data_players_all',
                'central_data_teams_all',
                'central_data_data_sources_all',
                'central_data_players_viewer',
                'users_admin',
                'organisations_admin',
                'my_organisation_owner',
                'graphql_playground_view',
                'documentation_view',
                'content_permissions_admin',
                'competitor_center_view',
                'sdk_demo_view',
                'stats_explorer_view',
            ],
        },
        {
            action: 'create',
            permissions: [
                'ops_tooling_admin',
                'central_data_series_all',
                'central_data_titles_all',
                'central_data_tournaments_all',
                'central_data_players_all',
                'central_data_teams_all',
                'central_data_data_sources_all',
                'users_admin',
                'organisations_admin',
            ],
        },
        {
            action: 'update',
            permissions: [
                'ops_tooling_admin',
                'central_data_series_all',
                'central_data_titles_all',
                'central_data_tournaments_all',
                'central_data_players_all',
                'central_data_teams_all',
                'central_data_data_sources_all',
                'users_admin',
                'organisations_admin',
            ],
        },
        {
            action: 'delete',
            permissions: [
                'ops_tooling_admin',
                'central_data_series_all',
                'central_data_titles_all',
                'central_data_tournaments_all',
                'central_data_players_all',
                'central_data_teams_all',
                'central_data_data_sources_all',
                'users_admin',
                'organisations_admin',
            ],
        },
        {
            action: 'execute',
            permissions: [
                'ops_tooling_admin',
                'central_data_series_all',
                'central_data_titles_all',
                'central_data_tournaments_all',
                'central_data_teams_all',
                'central_data_data_sources_all',
                'users_admin',
                'organisations_admin',
            ],
        },
        {
            action: 'write',
            permissions: ['manual_action_veto_api'],
        },
    ],
};

const getPermissions = rest.get('/auth/permissions', (req, res, ctx) =>
    res(ctx.json(permissionsResponse)),
);

const getOrganisationsContentPermissions = rest.get(
    '/api/permissions/content/:parentEntity/:id/organisations',
    (req, res, ctx) =>
        req.url.search !== '?queryString='
            ? res(ctx.json(filteredContentPermissionsMock))
            : res(ctx.json(contentPermissionsMock)),
);

const getContentPermissionsForOrganisations = rest.get(
    '/api/permissions/v2/organisations',
    (req, res, ctx) => res(ctx.json(contentPermissionsMock)),
);

const setContentPermission = rest.post(
    '/api/permissions/v2/contentPermissions/:subjectType/:subjectId',
    (req, res, ctx) => res(ctx.json({})),
);

const setOrganisationContentPermissions = rest.patch(
    '/api/permissions/content/:parentEntity/:id/organisations',
    (req, res, ctx) => {
        const organisationId = req.body![0]!.id;
        const organisation = organisations.find(
            (someOrganisation) => someOrganisation.id === organisationId,
        );
        organisation!.hasPermission = true;
        return res(ctx.json({ organisations: { id: organisationId } }));
    },
);

const deleteOrganisationContentPermissions = rest.post(
    '/api/permissions/content/:parentEntity/:id/organisations/delete',
    (req, res, ctx) => {
        const organisationId = req.body![0]!.id;
        const organisation = organisations.find(
            (someOrganisation) => someOrganisation.id === organisationId,
        );
        organisation!.hasPermission = false;
        return res(ctx.json({ organisations: { id: organisationId } }));
    },
);

const setUserContentPermissions = rest.patch(
    '/api/permissions/content/:parentEntity/:id/users',
    (req, res, ctx) => {
        const userId = req.body![0]!.id;
        const user = users.find((someUser) => someUser.id === userId);
        user!.hasPermission = true;
        return res(ctx.json({ organisations: { id: userId } }));
    },
);

const deleteUserContentPermissions = rest.post(
    '/api/permissions/content/:parentEntity/:id/users/delete',
    (req, res, ctx) => {
        const userId = req.body![0]!.id;
        const user = users.find((someUser) => someUser.id === userId);
        user!.hasPermission = false;
        return res(ctx.json({ organisations: { id: userId } }));
    },
);

const hasContentPermission = rest.get(
    '/auth/permissions/content/check/series/:id',
    (req, res, ctx) => {
        const code = req.params.id === '8851' ? 401 : 200;
        return res(ctx.status(code), ctx.json({}));
    },
);

export default [
    deleteOrganisationContentPermissions,
    deleteUserContentPermissions,
    getContentPermissionsForOrganisations,
    getOrganisationsContentPermissions,
    getPermissions,
    hasContentPermission,
    setContentPermission,
    setOrganisationContentPermissions,
    setUserContentPermissions,
];
